<template>
  <div>
    <br />
    <div class="row">
      <div class="col-3">
        <label for="">Client</label>
        <select
          class="form-select"
          v-model="payment.customer_reference"
          @change="getCustomerSalesUnpaid(payment.customer_reference)"
        >
          <option value="" default></option>
          <option
            v-for="(customer, index) in customers"
            :key="index++"
            :value="customer.reference"
          >
            <div class="row justify-content-between">
              <span class="col-auto">
                {{ customer.fullName }}
              </span>
              <span class="col-auto">
                {{ customer.society }}
              </span>
            </div>
          </option>
        </select>
      </div>

      <div class="col-7">
        <label for=""> Factures impayées </label>
        <select class="form-select" v-model="payment.sale_reference">
          <option value="" default></option>
          <option
            v-for="(sale, index) in sales"
            :key="index++"
            :value="sale.reference"
            :style="{ color: 'red' }"
          >
            <div class="row justify-content-between">
              Nº {{ sale.reference }}, Facturait le
              <span class="col-auto text-success">
                {{ sale.date }}
              </span>
              Payer
              <span :style="{ color: 'green' }" class="col-auto text-success">
                {{ sale.amountPaid }}
              </span>
              à partir de
              <span class="col-auto">
                {{ sale.totalPriceTTC }}
              </span>
              reste
              <span class="col-auto text-danger">
                {{ sale.amountUnpaid }}
              </span>
            </div>
          </option>
        </select>
      </div>
      <div class="col-2">
        <label for="">Montant</label>
        <input type="text" v-model="payment.amount" class="form-control" />
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-3">
        <label for="">Date</label>
        <input type="text" v-model="payment.date" class="form-control" />
      </div>
      <div class="col-3">
        <label for="">Mode de paiement</label>
        <select v-model="payment.paymentMethod" class="form-select">
          <option value="Espèce">Espèce</option>
          <option value="Virement bancaire">Virement bancaire</option>
          <option value="Chèque">Chèque</option>
          <option value="Effet">Effet</option>
        </select>
      </div>

      <div class="col-3">
        <label for="">Numéro de chèque</label>
        <input type="text" v-model="payment.checkNumber" class="form-control" />
      </div>

      <div class="col-3">
        <label for="">Numéro de virement bancaire</label>
        <input
          type="text"
          v-model="payment.bankTransferNumber"
          class="form-control"
        />
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-12">
        <label for="">Remarques</label>
        <textarea class="form-control" cols="30" rows="3"></textarea>
      </div>
    </div>
    <br />
    <div class="row mt-3 justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(payment)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },

  mounted() {
    this.$store.dispatch("payment/show", this.$route.params.reference);
    this.$store.dispatch("customer/getAll");
  },
  computed: {
    ...mapGetters("payment", {
      payment: "getPayment",
    }),
    ...mapGetters("customer", {
      customers: "getAll",
    }),
  },
  methods: {
    async refresh() {
      await this.$store.dispatch("payment/show", this.$route.params.reference);
      this.$store.dispatch("customer/getAll");
    },
     async save(data) {
      await this.$store.dispatch("payment/update", data);
    },
  },
};
</script>
